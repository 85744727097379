import {FC, useContext, useEffect, useState} from 'react'
import MainCard from '../../../components/components/MainCard';
import InvestorCard from './components/InvestorCard/InvestorCard';
import { Box } from '@mui/material';
import InvestorList from './components/InvestorList/InvestorList';
import { getFavoritesInvestors, getTemporalInvestors } from '../../../api/fundraising';
import { Investor } from '../../../types/fundraising';
import { useNavigate } from 'react-router-dom';
import { Paragraph } from '../../../styled/Paragraph';
import { ButtonPrimary } from '../../../styled/Button';
import { AppContext } from '../../../context';
import { ButtonContainer, HeaderContainer } from '../../../styled/Fundraising';

interface FundraisingProps {
  view?: 'my-list' | 'initial'
}
const Fundraising: FC<FundraisingProps> = ({ view = 'initial' }) => {
  const navigate = useNavigate();
  // TODO: Delete this after we have data from API endpoint
  const checkSize = '$50k - 150k'
  const { getStartupProfile, startupProfile } = useContext(AppContext)
  const [investors, setInvestors] = useState<Investor[]>([])
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [favInvestors, setFavInvestors] = useState<Investor[]>([])
  // const [currentFavPage, setCurrentFavPage] = useState<number>(1);
  // const [totalFavPages, setTotalFavPages] = useState<number>(1);

  const featuredInvestor = {
    city: "Mountain View",
    country: "united states",
    description: "Michael has partnered with Founders on 80+ pre-seed investments via funds totaling over $150M and has held over a dozen board roles supporting Founders going from zero-to-one. He has amassed over a decade of experience at the earliest stages of deep tech venture creation. After assessing x0,000s of ideas and meeting with thousands of founders, Michael constructed Bee Partners’ Frontier Curve of Innovation™ framework to identify the most promising deals. He currently serves on the boards of Embroker, Voltaiq, Cyber Pop-up, and one still in stealth.",
    firm_name: "Bee Partners",
    first_name: "Michael",
    founding_year: "2014",
    fund_stage: "Seed,Pre-Seed,Series A",
    fund_type: "Venture Fund",
    id: 2,
    industry: "B2B SaaS, AI/ML, Enterprise, AgiTech, HardTech",
    isSaved: false,
    last_name: "Berolzheimer",
    linkedin_link: "https://www.linkedin.com/company/beepartnersvc/",
    location: "Mountain View",
    state: "California",
    status: "Verified",
    test: "checked",
    twitter_link: "https://x.com/BeePartners",
    website: "https://beepartners.vc/get-funded"
  }

  const getTemporalInvestorsData = async (searchQuery: string, page: number) => {
    setLoading(true);
    if(startupProfile?.id) {
      try {
        const res = await getTemporalInvestors(searchQuery, page, startupProfile?.id);
        setInvestors(res.results);
        setTotalPages(res.total_pages);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const getFavoritesInvestorsData = async (searchQuery: string) => {
    setLoading(true);
    if(startupProfile?.id) {
      try {
        const res = await getFavoritesInvestors(searchQuery, startupProfile?.id);
        const favInv = res.map((item: any) => item.investor);
        setFavInvestors(favInv);
        // setTotalFavPages(res.total_pages);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getStartupProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getTemporalInvestorsData(searchQuery, currentPage);
    getFavoritesInvestorsData(searchQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, currentPage, startupProfile?.id]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSearchQuery(inputValue);
    setCurrentPage(1);
  };

  const formatHeader = (header: string) => {
    return header
      .split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const exportToCSV = (data: any) => {
    const csvRows = [];

    const headers = Object.keys(data[0]);
    const formattedHeaders = headers.map(formatHeader);
    csvRows.push(formattedHeaders.join(','));

    data.forEach((item: any) => {
      const values = headers.map((header) => {
        const value = item[header];
        const escapedValue = String(value).replace(/"/g, '\\"');
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'investors.csv';
    a.click();
  };

  return (
    <>
       <Box sx={{ margin: '70px 0px', display: 'flex', flexDirection: 'column', gap: 4}}>
        <div style={{ maxWidth: '904px'}}>
          <Paragraph fontSize={14} fontWeight='500'>Drive your startup’s growth with dilutive & non-dilutive capital</Paragraph>
          <Paragraph fontSize={40} fontWeight='400' color='#035928'>Raise capital for your startup</Paragraph>
          <HeaderContainer>
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <input
              style={{
                width: "100%",
                height: "52px",
                padding: "16px",
                borderRadius: "30px",
                border: "1px solid #E0E0E0"
              }}
              type='text'
              aria-label="Search"
              placeholder="Search"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              />
            </form>
            <ButtonContainer>
              <ButtonPrimary $height={52}>Equity Financing</ButtonPrimary>
              <ButtonPrimary $height={52} $notSelected>Debt Financing</ButtonPrimary>
            </ButtonContainer>
          </HeaderContainer>
        </div>
       {view === 'initial' ? (
        // Render /fundraising
        <>
          <MainCard title={'Featured Investor of the Week'}>
            <InvestorCard
              investor={featuredInvestor}
              isInvestor={true}
              checkSize={checkSize}
            />
          </MainCard>
          <MainCard
            title={'Top Matches'}
            pagination
            buttonTitle='View my list'
            onClick={async () => {
              await getFavoritesInvestorsData(searchQuery)
              navigate("/fundraising/my-list")
            }}
            onPageChange={(page) =>{
              setCurrentPage(page);
              getTemporalInvestorsData(searchQuery, page);
            }}
            totalPages={totalPages}
            >
            <InvestorList investors={investors}/>
          </MainCard>
        </>
        ) : (
          // Render /fundraising/my-list
          <MainCard
              title={'My List'}
              // onClick={() => getFavoritesInvestorsData(searchQuery)}
              onClick={() => exportToCSV(favInvestors)}
              buttonTitle='Export as CSV'
            >
            <InvestorList investors={favInvestors} isFavList updateData={getFavoritesInvestorsData}/>
          </MainCard>
        )}
       </Box>
    </>
  )
}

export default Fundraising