import React, { FC, useState, useEffect, useContext, useCallback } from "react";
import { Grid, Box } from "@mui/material";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import slugify from "slugify";
import { IoShareSocialOutline } from "react-icons/io5";
import { BiWorld } from "react-icons/bi";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import { AiOutlineQuestionCircle } from "react-icons/ai";
//Custom card components
import CardStartupDescription from "../components/StepCards/CardStartupDescription";
import CardEssentialInformation from "../components/StepCards/CardEssentialInformation";
import CardCompanyNoContent from "../components/StepCards/CardCompanyNoContent";
import CardBusinessTraction from "../components/StepCards/CardBusinessTraction";
import CardInvestment from "../components/StepCards/CardInvestment";
import CardPitchDeck from "../components/StepCards/CardPitchDeck";
import StepsPath from "../components/StepsPath";
import { Paragraph } from "../../../../styled/Paragraph";
import ShareProfileModal from "../../../private/Share/ShareProfileModal";
//Api calls
import {
  editStartupPrivacy,
  getAllStartupProfile,
} from "../../../../helpers/Api";
import CardFounders from "../components/StepCards/CardFounders";
import CardVideo from "../components/StepCards/CardVideo";
//Custom components
import ModalShareProfile from "../../../private/Share/ModalShareProfile";
import { ButtonSecondary } from "../../../../styled/Button";
import { AppContext } from "../../../../context";

const BuildProfile: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const {
    getStartupProfile,
    startupProfile,
    loading,
    setStartupPublicLink,
    isSharingEligible,
    checkSharingEligibility,
  } = useContext(AppContext);
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);

  const openShareModal = () => setShareModalIsOpen(true);
  const closeShareModal = () => setShareModalIsOpen(false);

  const [switchLoading, setSwitchLoading] = useState(false);
  //This State it's a watchdog to update the data when the user updates the information
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [publicURL, setPublicURL] = useState<string | null | undefined>();

  const handleSwitchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (startupProfile!.id) {
      setSwitchLoading(true);
      try {
        const newValue = event.target.checked;
        const response = await editStartupPrivacy(
          {
            isPrivacy: newValue,
          },
          startupProfile!.id
        );
        if (response === 200) {
          setIsPublic(newValue);
          if (newValue) {
            const slugName = startupProfile
              ? createSlug(startupProfile.name)
              : "";
            const url = `${process.env.REACT_APP_BASE_URL}/share/${startupProfile?.id}/${slugName}`;
            setPublicURL(url);
            setStartupPublicLink(url);
            openShareModal(); // Open the modal only when switching ON
          } else {
            setPublicURL(null);
            setStartupPublicLink(""); // Clearing the URL in AppContext
            // No modal open when switching OFF
          }
        } else {
          console.error("Error updating privacy setting");
        }
      } catch (error) {
        console.error("Error while updating privacy setting", error);
      } finally {
        setSwitchLoading(false);
      }
    }
  };

  const handleCancelShare = async () => {
    if (startupProfile?.id) {
      try {
        const response = await editStartupPrivacy(
          { isPrivacy: false },
          startupProfile.id
        );
        if (response === 200) {
          setIsPublic(false);
          setStartupPublicLink("");
        }
      } catch (error) {
        console.error("Error updating privacy setting", error);
      }
    }
  };

  const createSlug = (text: string) => {
    return slugify(text, {
      lower: true,
      strict: true,
    });
  };

  const buildUld = useCallback(() => {
    if (startupProfile) {
      const slugName = createSlug(startupProfile.name);
      const url:
        | string
        | null
        | undefined = `${process.env.REACT_APP_BASE_URL}/share/${startupProfile.id}/${slugName}`;
      setPublicURL(url);
      setStartupPublicLink(url);
    }
  }, [startupProfile, setStartupPublicLink]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    getStartupProfile();
  }, [getStartupProfile]);

  useEffect(() => {
    if (startupProfile) {
      checkSharingEligibility(startupProfile);
    }
  }, [startupProfile, checkSharingEligibility]);

  useEffect(() => {
    if (startupProfile) {
      setIsPublic(startupProfile!.isPublic);
    }
  }, [startupProfile]);

  useEffect(() => {
    buildUld();
  }, [startupProfile, buildUld]);

  return (
    <>
      {loading.isLoading && loading.loader.includes("startupProfile") ? (
        <Grid container direction="column">
          <Grid
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            md={12}
          >
            <CircularProgress size={70} color="inherit" />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            container
            className="buildProfile__box"
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
            sx={{
              padding: { xs: "16px", sm: "24px", md: "32px" },
              width: "100%",
            }}
          >
            <Grid item md={12}>
              <Paragraph fontSize={14} fontWeight="500">
                {" "}
                Add all the information about your startup to complete the
                profile
              </Paragraph>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  flexWrap: {
                    xs: 'wrap',
                    md: 'nowrap'
                  }
                }}
              >
                <Paragraph fontSize={40} fontWeight="400" color="#035928">
                  Keep going! Build your startup profile
                </Paragraph>
                <ButtonSecondary
                  disabled={!isSharingEligible}
                  $isDisabled={!isSharingEligible}
                  onClick={openModal}
                >
                  <IoShareSocialOutline size={24} /> Share
                </ButtonSecondary>
              </Box>
            </Grid>

            <Grid mt={12} item md={12}>
              <StepsPath
                Information={startupProfile?.name}
                Description={startupProfile?.description}
                Founders={startupProfile?.founders}
                Traction={startupProfile?.businessTractions?.businessModel}
                Investment={startupProfile?.hasInvestors}
                PitchDeck={startupProfile?.pitchDeckUrl}
                Video={startupProfile?.videoUrl}
              />
            </Grid>
            <ModalShareProfile
              modalIsOpen={isOpen}
              closeModal={closeModal}
              startupId={startupProfile?.id}
            />
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            className="buildProfile__box"
            sx={{
              padding: { xs: "16px", sm: "24px", md: "32px" },
              width: "100%",
            }}
          >
            <Grid item md={12}>
              {isPublic ? (
                <>
                  <ShareProfileModal
                    modalIsOpen={shareModalIsOpen}
                    closeModal={closeShareModal}
                    onCancel={handleCancelShare}
                    // url={publicURL}
                  />
                </>
              ) : null}
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent="flex-end"
                alignItems="center"
                maxWidth={"904.086px"}
              >
                <p style={{ color: "#04BF7B" }}>
                  <BiWorld
                    style={{ margin: "0px 5px 4px" }}
                    size={22}
                    color="#04BF7B"
                  />
                  Make startup profile public?
                  <Switch
                    sx={{ marginBottom: "2px" }}
                    checked={isPublic}
                    onChange={handleSwitchChange}
                    disabled={switchLoading}
                    {...label}
                  />
                  <Tooltip title="A link will be created for you to share, granting access to startup's profile for anyone.">
                    <IconButton>
                      <AiOutlineQuestionCircle size={22} />
                    </IconButton>
                  </Tooltip>
                </p>
              </Box>
              <CardEssentialInformation
                location={startupProfile?.location}
                name={startupProfile?.name}
                industry={startupProfile?.industry}
                employeeCount={startupProfile?.employeeCount}
                stage={startupProfile?.stage}
                websiteUrl={startupProfile?.websiteUrl}
                userId={startupProfile?.id!}
                imageUrl={startupProfile?.imageUrl}
              />
            </Grid>
            <Grid mt={8} item md={12}>
              {startupProfile?.description ? (
                <CardStartupDescription
                  title={"Startup Description"}
                  description={startupProfile?.description}
                  userId={Number(startupProfile?.id!)}
                />
              ) : (
                <CardCompanyNoContent
                  title={"Startup Description"}
                  description={
                    "Introduce your startup. What problem are you solving? What makes your solution unique?"
                  }
                  titleButton="Description"
                  idButtonOpener="stp"
                  userId={startupProfile?.id!}
                />
              )}
            </Grid>

            <Grid mt={8} item md={12}>
              {startupProfile?.founders && (
                <CardFounders
                  dataFounders={startupProfile.founders}
                  userId={startupProfile?.id!}
                />
              )}
            </Grid>
            <Grid mt={8} item md={12}>
              {startupProfile?.businessTractions ? (
                <CardBusinessTraction
                  title="Business Traction"
                  dataTraction={startupProfile?.businessTractions}
                  userId={startupProfile?.id!}
                />
              ) : (
                <CardCompanyNoContent
                  title="Business Traction"
                  description="Share your startup's real-world progress. How well is your business gaining momentum?"
                  titleButton="Traction"
                  idButtonOpener="biz"
                  userId={startupProfile?.id!}
                />
              )}
            </Grid>
            <Grid mt={8} item md={12}>
              {startupProfile?.hasInvestors ? (
                <CardInvestment
                  title={"Investment"}
                  userId={startupProfile?.id!}
                />
              ) : (
                <CardCompanyNoContent
                  title="Investment"
                  description="Showcase your startup's supporters. Detail funding rounds, including pre-seed amounts and investors."
                  titleButton="Add Round"
                  idButtonOpener="inv"
                  userId={startupProfile?.id!}
                />
              )}
            </Grid>
            <Grid mt={8} item md={12}>
              {startupProfile?.pitchDeckUrl ? (
                <CardPitchDeck
                  userId={startupProfile?.id!}
                  urlPitch={startupProfile?.pitchDeckUrl!}
                />
              ) : (
                <CardCompanyNoContent
                  title="Pitch Deck"
                  description="Upload your pitch deck to showcase your business to potential investors"
                  titleButton="Upload"
                  idButtonOpener="pit"
                  userId={startupProfile?.id!}
                />
              )}
            </Grid>
            <Grid mt={8} item md={12}>
              {startupProfile?.videoUrl ? (
                <CardVideo
                  id={startupProfile?.id!}
                  videoUrl={startupProfile?.videoUrl}
                />
              ) : (
                <CardCompanyNoContent
                  title="Video"
                  description="Upload a video that introduces your startup. Tell us about your vision and what makes you unique"
                  titleButton="Upload"
                  idButtonOpener="vid"
                  userId={startupProfile?.id!}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default BuildProfile;
